import axios from '@/plugins/axios';
import {
    Company,
    CompanyRegistrationResponse,
    CompanyDetailResponse,
    CompanyListResponse,
    CompanyRegistration,
    ICompanyRepository,
    ApiKeyInfo,
    ApiKeyUpdateInfo,
} from '@/models/interface/Management/ICompanyRepository';
import { CommonResponse } from '@/models/interface/common';

export class CompanyRepository implements ICompanyRepository {
    async getCompanyList(): Promise<CompanyListResponse> {
        return await axios.get(`/api/management/companies`).catch(error => error.response || error);
    }

    async getCompanyDetail(id: Company['id']): Promise<CompanyDetailResponse> {
        return await axios.get(`/api/management/companies/${id}`).catch(error => error.response || error);
    }

    async postCompany(request: CompanyRegistration): Promise<CompanyRegistrationResponse> {
        return await axios.post('/api/management/company', request).catch(error => error.response || error);
    }

    async deleteCompany(id: Company['id']): Promise<CommonResponse> {
        return await axios.delete(`/api/management/companies/${id}`).catch(error => error.response || error);
    }

    async postApiKey(companyId: Company['id']): Promise<CommonResponse> {
        return await axios
            .post(`/api/management/companies/${companyId}/api-key`)
            .catch(error => error.response || error);
    }

    async putApiKey(companyId: Company['id'], apiKey: ApiKeyUpdateInfo): Promise<CommonResponse> {
        return await axios
            .put(`/api/management/companies/${companyId}/api-keys/${apiKey.id}`, { memo: apiKey.memo })
            .catch(error => error.response || error);
    }

    async deleteApiKey(companyId: Company['id'], apiKeyId: ApiKeyInfo['id']): Promise<CommonResponse> {
        return await axios
            .delete(`/api/management/companies/${companyId}/api-keys/${apiKeyId}`)
            .catch(error => error.response || error);
    }
}
