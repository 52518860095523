import axios from 'axios';
import { IExternalLoginRepository, ExternalLoginResponse } from '@/models/interface/Auth/IExternalLoginRepository';

export class ExternalLoginRepository implements IExternalLoginRepository {
    async postExternalLogin(onetimeToken: string | undefined): Promise<ExternalLoginResponse> {
        return await axios
            .post('/api/login', {
                onetimeToken: onetimeToken,
            })
            .catch(error => error.response || error);
    }
}
