import axios from 'axios';
import { IFileRepository, FileListResponse, FileCreationResponse } from '@/models/interface/File/IFileRepository';
import { CommonResponse } from '@/models/interface/common';

export class FileRepository implements IFileRepository {
    async getFileIndex(): Promise<FileListResponse> {
        return await axios.get('/api/files').catch(error => error.response || error);
    }

    async putFileName(fileId: number, fileName: string): Promise<CommonResponse> {
        return await axios.put(`/api/files/${fileId}/name`, { name: fileName }).catch(error => error.response || error);
    }

    async putFeaturedImage(fileId: number, image: string): Promise<CommonResponse> {
        return await axios
            .put(`/api/files/${fileId}/featured-image`, { image: image })
            .catch(error => error.response || error);
    }

    async postFile(templateId: number): Promise<FileCreationResponse> {
        return await axios.post(`/api/files/${templateId}`).catch(error => error.response || error);
    }
}
