import router from '@/router';
import axios from 'axios';

const axiosInstance = axios.create();
axiosInstance.interceptors.request.use(config => {
    const platformToken = localStorage.getItem('platformToken');

    if (platformToken === null) return config;

    config.headers = {
        Authorization: 'Bearer ' + platformToken,
    };

    return config;
});

axiosInstance.interceptors.response.use(
    response => {
        return response;
    },
    async error => {
        if (error.response?.status === 401) {
            localStorage.clear();
            router.push({ name: 'PlatformLoginPage' });
        }
    },
);

export default axiosInstance;
