import { usePageTitle } from '@/composables/function/Common/usePageTitle';
import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: { name: 'FileList' },
        meta: { isAuthRequired: true },
    },
    {
        path: '/home',
        redirect: { name: 'FileList' },
        meta: { isAuthRequired: true },
    },
    {
        path: '/external-login',
        name: 'ExternalLogin',
        component: () => import('@/components/Auth/environments/ExternalLogin.vue'),
        beforeEnter: (to, from, next) => {
            const isLogin = !!sessionStorage.getItem('isAuthenticated');

            // NOTE: 認証済みの場合ログイン画面へは遷移させない
            if (isLogin) next({ name: 'FileList' });
            else next();
        },
    },
    // NOTE: 外部システムのモックへの遷移は禁止する
    // {
    //     path: '/external-system-mock',
    //     component: () => import('@/components/ExternalSystemMock/environments/ExternalSystemMock.vue'),
    //     children: [
    //         {
    //             path: 'login',
    //             name: 'LoginMock',
    //             component: () => import('@/components/ExternalSystemMock/organisms/ExternalAuthorizationMockForm.vue'),
    //         },
    //         {
    //             path: 'data-linkage',
    //             name: 'DataLinkageMock',
    //             component: () => import('@/components/ExternalSystemMock/ecosystems/DataLinkageMock.vue'),
    //         },
    //     ],
    // },
    {
        path: '/home',
        component: () => import('@/components/Home/environments/HomePage.vue'),
        meta: { isAuthRequired: true },
        children: [
            {
                path: 'file-list',
                name: 'FileList',
                component: () => import('@/components/Home/ecosystems/FileList.vue'),
                meta: { title: 'ファイル一覧' },
            },
            {
                path: 'template-list',
                name: 'TemplateList',
                component: () => import('@/components/Home/ecosystems/TemplateList.vue'),
                meta: { title: 'テンプレート一覧' },
            },
        ],
    },
    {
        // NOTE: 外部から直接アクセスできるように、ファイル編集コンポーネント内で認証を行える
        path: '/file/:fileId',
        component: () => import('@/components/File/environments/FileEditor.vue'),
        meta: { isAuthRequired: false },
        children: [
            {
                path: '',
                redirect: { name: 'DrawingPage' },
            },
            {
                path: ':displayState',
                name: 'DrawingPage',
                component: () => import('@/components/File/ecosystems/DrawingPage.vue'),
            },
        ],
    },
    {
        path: '/platform-login',
        name: 'PlatformLoginPage',
        meta: { title: 'ログイン' },
        component: () => import('@/components/PlatformAuth/environments/LoginPage.vue'),
        beforeEnter: (to, from, next) => {
            const isPlatformLogin = !!localStorage.getItem('platformToken');

            // NOTE: 認証済みの場合ログイン画面へは遷移させない
            if (isPlatformLogin) next({ name: 'CompanyList' });
            else next();
        },
    },
    {
        path: '/management/company',
        component: () => import('@/components/Management/environments/ManagementPage.vue'),
        meta: { isPlatformAuthRequired: true },
        children: [
            {
                path: '',
                name: 'CompanyList',
                component: () => import('@/components/Management/ecosystems/CompanyList.vue'),
                meta: { title: '会社一覧' },
            },
            {
                path: ':companyId',
                name: 'CompanyDetail',
                component: () => import('@/components/Management/ecosystems/CompanyDetail.vue'),
                meta: { title: '会社詳細', parent: 'CompanyList' },
            },
            {
                path: 'registration',
                name: 'CompanyRegistration',
                component: () => import('@/components/Management/ecosystems/CompanyRegistration.vue'),
                meta: { title: '会社登録' },
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory('/'),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) return savedPosition;
        return { top: 0 };
    },
});

router.beforeEach((to, from, next) => {
    const isAuthRequired = to.matched.some(record => record.meta.isAuthRequired);
    const isPlatformAuthRequired = to.matched.some(record => record.meta.isPlatformAuthRequired);

    const isLogin = !!sessionStorage.getItem('isAuthenticated');
    const isPlatformLogin = !!localStorage.getItem('platformToken');

    if (!isLogin && isAuthRequired) {
        // NOTE: 無印にログインしていない場合に、無印の認証が必要なパスへアクセスすると、無印のモックログイン画面へ遷移
        next({ name: 'ExternalLogin' });
        return;
    }
    if (!isPlatformLogin && isPlatformAuthRequired) {
        // NOTE: PFにログインしていない場合に、PFの認証が必要なパスへアクセスすると、ログイン画面へ遷移
        next({ name: 'PlatformLoginPage' });
        return;
    }
    next();
});

router.afterEach(to => {
    const { titleWithProductName } = usePageTitle();
    document.title = titleWithProductName(to.meta.title as string | undefined);
});

export default router;
