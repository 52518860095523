import axios from 'axios';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useAccessTokenSetting = () => {
    const setAxios = (token: string): void => {
        axios.interceptors.request.use(config => {
            if (!config.headers) {
                return;
            }

            config.headers.Authorization = `Bearer ${token}`;
            return config;
        });
    };

    return {
        setAxios,
    };
};
