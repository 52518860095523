import axios from 'axios';
import {
    IExternalSystemMockRepository,
    GenerateOnetimeTokenResponse,
    AuthenticationInfo,
} from '@/models/interface/ExternalSystemMock/IExternalSystemMockRepository';

export class ExternalSystemMockRepository implements IExternalSystemMockRepository {
    async postGenerateOnetimeToken(authenticationInfo: AuthenticationInfo): Promise<GenerateOnetimeTokenResponse> {
        return await axios.post('/api/generate/token', authenticationInfo).catch(error => error.response || error);
    }
}
