import 'vuetify/styles';
import { createVuetify, ThemeDefinition } from 'vuetify';

const myCustomLightTheme: ThemeDefinition = {
    dark: false,
    colors: {
        background: '#FFFFFF',
        pageBackground: '#F1EEEE',
        primary: '#1568F4',
        secondary: '#E1EBFC',
        activeButton: '#D2E1FA',
        activeBox: '#327FFA',
        pfPrimary: '#3683cf',
    },
};

export default createVuetify({
    theme: {
        defaultTheme: 'myCustomLightTheme',
        themes: {
            myCustomLightTheme,
        },
    },
});
