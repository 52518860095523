import { inject, provide, Ref, ref } from 'vue';
import { useAccessTokenSetting } from '@/composables/function/Auth/useAccessTokenSetting';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const provideAuthenticationInfo = () => {
    const initParam = {
        isAuthenticated: sessionStorage.getItem('isAuthenticated') === 'true',
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        token: sessionStorage.getItem('token') === null ? '' : sessionStorage.getItem('token')!,
    };

    const isAuthenticated = ref<boolean>(initParam.isAuthenticated);
    const accessToken = ref<string>(initParam.token);

    provide('isAuthenticated', isAuthenticated);
    provide('accessToken', accessToken);

    return {
        accessToken,
    };
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useAuthenticationInfo = () => {
    const isAuthenticated = inject('isAuthenticated') as Ref<boolean>;
    const accessToken = inject('accessToken') as Ref<string>;

    const { setAxios } = useAccessTokenSetting();

    const setAuthenticationInfo = (authStatus: boolean, token: string): void => {
        isAuthenticated.value = authStatus;
        accessToken.value = token;
        sessionStorage.setItem('isAuthenticated', 'true');
        setAxios(token);
    };

    const initAuthenticationInfo = (): void => {
        isAuthenticated.value = false;
    };

    const isLogin = (): boolean => {
        return isAuthenticated.value;
    };

    return {
        isAuthenticated,
        accessToken,
        setAuthenticationInfo,
        initAuthenticationInfo,
        isLogin,
    };
};
