import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import VueKonva from 'vue-konva';
import 'reflect-metadata';
import { container } from 'tsyringe';
import { ExternalSystemMockRepository } from '@/models/repository/ExternalSystemMock/ExternalSystemMockRepository';
import { ExternalLoginRepository } from '@/models/repository/Auth/ExternalLoginRepository';
import { FileRepository } from './models/repository/File/FileRepository';
import { TemplateRepository } from './models/repository/Home/TemplateRepository';
import { DrawingDataRepository } from './models/repository/File/DrawingDataRepository';
import { CompanyRepository } from './models/repository/Management/CompanyRepository';
import { AuthRepository } from './models/repository/PlatformAuth/AuthRepository';

const app = createApp(App);

app.use(router);
app.use(VueKonva, { prefix: 'canvas' });
app.use(vuetify);
app.mount('#app');

// 認証関連
container.register('IExternalSystemMockRepository', { useClass: ExternalSystemMockRepository });
container.register('IExternalLoginRepository', { useClass: ExternalLoginRepository });

// Platform認証関連
container.register('IAuthRepository', { useClass: AuthRepository });

// File関連
container.register('IFileRepository', { useClass: FileRepository });
container.register('ITemplateRepository', { useClass: TemplateRepository });
container.register('IDrawingDataRepository', { useClass: DrawingDataRepository });

// 顧客管理関連
container.register('ICompanyRepository', { useClass: CompanyRepository });
