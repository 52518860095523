// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const usePageTitle = () => {
    const DEFAULT_TITLE = 'wasabi';

    const pageTitle = (title: string | undefined): string => {
        return title ? title : DEFAULT_TITLE;
    };

    const titleWithProductName = (title: string | undefined): string => {
        return title ? `${title} | ${DEFAULT_TITLE}` : DEFAULT_TITLE;
    };

    return {
        pageTitle,
        titleWithProductName,
    };
};
