import axios from 'axios';
import {
    IDrawingDataRepository,
    DrawingDataResponse,
    CommonBoxGroupResponse,
    BoxGroup,
    Box,
} from '@/models/interface/File/IDrawingDataRepository';

export class DrawingDataRepository implements IDrawingDataRepository {
    async getDrawingData(fileId: number): Promise<DrawingDataResponse> {
        return await axios.get(`/api/files/${fileId}/drawing-data`).catch(error => error.response || error);
    }

    async postBoxGroup(request: BoxGroup, fileId: number): Promise<CommonBoxGroupResponse> {
        return await axios.post(`/api/files/${fileId}/box-group`, request).catch(error => error.response || error);
    }

    async deleteBoxGroup(id: string, fileId: number): Promise<CommonBoxGroupResponse> {
        return await axios.delete(`/api/files/${fileId}/box-group/${id}`).catch(error => error.response || error);
    }

    async putTextBoxStyle(textBox: Box, fileId: number): Promise<CommonBoxGroupResponse> {
        return await axios
            .put(`/api/files/${fileId}/box-group/text-box/${textBox.id}/style`, textBox.style)
            .catch(error => error.response || error);
    }

    async putDataBoxStyle(dataBox: Box, fileId: number): Promise<CommonBoxGroupResponse> {
        return await axios
            .put(`/api/files/${fileId}/box-group/data-box/${dataBox.id}/style`, dataBox.style)
            .catch(error => error.response || error);
    }
}
